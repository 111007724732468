// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCOBLXZu7Cv-phMb4PnM_4FPMTkghMvobk",
  authDomain: "analytica-ai-30c69.firebaseapp.com",
  projectId: "analytica-ai-30c69",
  storageBucket: "analytica-ai-30c69.appspot.com",
  messagingSenderId: "307057654294",
  appId: "1:307057654294:web:f36c45946695e9a05f1337",
  measurementId: "G-FWYB4LKB3C"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app); // Optional if you're using Analytics
const db = getFirestore(app);

// Export Firebase auth functions
export { auth, signInWithEmailAndPassword, createUserWithEmailAndPassword, db };