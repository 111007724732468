import './style.css';
import javascriptLogo from './javascript.svg';
import viteLogo from '/vite.svg';
import { getOpenAIResponse, generateImage, transcribeAudio } from './openaiService';
import { auth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from './firebaseConfig';
import { sendEmailVerification } from 'firebase/auth'; // Correct import from 'firebase/auth'

document.addEventListener('DOMContentLoaded', () => {
  // Render the app HTML
  const appHTML = `
    <div id="app-content">

      <h1 style="color: #ffff00;">Hello! Meet Wissen</h1>

      <div>
        <h2 style="color: #ffff00;">Chat with Wissen</h2>
        <div class="input-container">
          <input id="prompt" placeholder="Enter your prompt" />
          <button id="micButton" class="mic-button"><i class="fa fa-microphone"></i></button>
        </div>
        <button id="fetchResponse">Get Response</button>
        <div id="dialogContainer"></div>
      </div>

      <div>
        <h2 style="color: #ffff00;">Sign Up or Login</h2>
        <button id="signupButton">Sign Up</button>
        <button id="loginButton">Login</button>
        <div id="authContainer"></div>
      </div>

      <div>
        <h2 style="color: #ffff00;">Generate Image with Wissen!</h2>
        <input id="imagePrompt" placeholder="Enter your image prompt" />
        <button id="fetchImage">Generate Image</button>
        <div id="imageContainer"></div>
        <div id="spinner" class="spinner"></div>
      </div>
      <footer><h2 style="color: #0000ff;">Powered by</h2></footer>
      <!-- Modal for Sign Up -->
      <div id="signupModal" class="modal">
        <div class="modal-content">
          <span class="close">&times;</span>
          <h2>Sign Up</h2>
          <input id="email" placeholder="Email" />
          <input id="password" placeholder="Password" type="password" />
          <button id="signupSubmitButton">Submit</button>
        </div>
      </div>

      <!-- Modal for Login -->
      <div id="loginModal" class="modal">
        <div class="modal-content">
          <span class="close-login">&times;</span>
          <h2>Login</h2>
          <input id="loginEmail" placeholder="Email" />
          <input id="loginPassword" placeholder="Password" type="password" />
          <button id="loginSubmitButton">Login</button>
        </div>

      </div>

      <a href="https://vitejs.dev" target="_blank">
        <img src="${viteLogo}" class="logo" alt="Vite logo" />
      </a>
      <a href="https://developer.mozilla.org/en-US/docs/Web/JavaScript" target="_blank">
        <img src="${javascriptLogo}" class="logo vanilla" alt="JavaScript logo" />
      </a>
    </div>
  `;

  // Attach rendered HTML to the DOM
  document.querySelector('#app').innerHTML = appHTML;

  // Attach event listeners for chat and image generation
  setupEventListeners();
});

// Function to set up event listeners
function setupEventListeners() {
  // Chat with OpenAI
  document.querySelector('#fetchResponse').addEventListener('click', fetchResponse);
  document.querySelector('#prompt').addEventListener('keypress', (event) => handleEnterKey(event, fetchResponse));

  // Generate image with OpenAI
  document.querySelector('#fetchImage').addEventListener('click', fetchImage);
  document.querySelector('#imagePrompt').addEventListener('keypress', (event) => handleEnterKey(event, fetchImage));

  // Microphone feature for speech-to-text
  document.querySelector('#micButton').addEventListener('click', handleMicrophone);

  // Sign-up and authentication modals
  document.querySelector('#signupButton').addEventListener('click', () => showModal('signupModal'));
  document.querySelector('#signupSubmitButton').addEventListener('click', handleSignUp);
  document.querySelector('.close').addEventListener('click', () => closeModal('signupModal'));
  window.addEventListener('click', (event) => outsideClickModal(event, 'signupModal'));

  // Show Login Modal
  document.querySelector('#loginButton').addEventListener('click', () => showModal('loginModal'));

  // Close Login Modal when clicking the "X" or outside the modal
  document.querySelector('.close-login').addEventListener('click', () => closeModal('loginModal'));
  window.addEventListener('click', (event) => outsideClickModal(event, 'loginModal'));

  // Handle Login
  document.querySelector('#loginSubmitButton').addEventListener('click', handleLogin);
}

// Modal Functions
function showModal(modalId) {
  document.getElementById(modalId).style.display = 'block';
}

function closeModal(modalId) {
  document.getElementById(modalId).style.display = 'none';
}

function outsideClickModal(event, modalId) {
  const modal = document.getElementById(modalId);
  if (event.target === modal) {
    modal.style.display = 'none';
  }
}

// Function to handle login
async function handleLogin() {
  const email = document.querySelector('#loginEmail').value;
  const password = document.querySelector('#loginPassword').value;
  const authContainer = document.querySelector('#authContainer');

  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    authContainer.textContent = `Logged in successfully! Welcome, ${userCredential.user.email}.`;

    // Hide the sign-up and login buttons after successful login
    document.querySelector('#signupButton').style.display = 'none';
    document.querySelector('#loginButton').style.display = 'none';

    // Optionally, display a logout button
    const logoutButton = document.createElement('button');
    logoutButton.textContent = 'Logout';
    logoutButton.id = 'logoutButton';
    document.querySelector('#authContainer').appendChild(logoutButton);

    // Add logout functionality
    logoutButton.addEventListener('click', handleLogout);

    // Close the login modal after successful login
    closeModal('loginModal');

    // Show the dashboard section and set up listeners
    showDashboard(userCredential.user.email);

  } catch (error) {
    authContainer.textContent = `Error logging in: ${error.message}`;
  }
}

// Function to display the dashboard after login
function showDashboard(userEmail) {
  const dashboardHTML = `
    <div id="dashboard">
      <h2>Welcome to your Dashboard, ${userEmail}</h2>
      <p>This is your personal dashboard where you can view your data, manage your profile, and more!</p>

      <div>
        <h3>Dashboard Content</h3>
        <p>Your custom content goes here.</p>

        <!-- Image Generator only for authenticated users -->
        <div id="imageGenerator">
          <h2>Generate Image with Wissen!</h2>
          <input id="imagePrompt" placeholder="Enter your image prompt" />
          <button id="fetchImage">Generate Image</button>
          <div id="imageContainer"></div>
          <div id="spinner" class="spinner"></div>
        </div>
      </div>
    </div>
  `;

  // Clear the existing content and display the dashboard
  document.querySelector('#app').innerHTML = dashboardHTML;

  // Re-attach event listeners after rendering the dashboard
  document.querySelector('#fetchImage').addEventListener('click', fetchImage);
  document.querySelector('#imagePrompt').addEventListener('keypress', (event) => handleEnterKey(event, fetchImage));
}

// Function to handle logout
async function handleLogout() {
  try {
    await auth.signOut(); // Firebase sign out

    // Reset the UI after logging out
    document.querySelector('#signupButton').style.display = 'block';
    document.querySelector('#loginButton').style.display = 'block';
    document.querySelector('#authContainer').textContent = '';

    // Remove the logout button
    const logoutButton = document.querySelector('#logoutButton');
    if (logoutButton) {
      logoutButton.remove();
    }

    console.log("Logged out successfully.");
  } catch (error) {
    console.error("Error logging out:", error);
  }
}

// Function to handle "Enter" key
function handleEnterKey(event, callback) {
  if (event.key === 'Enter') {
    callback();
  }
}

// Function to fetch the response from OpenAI and display it
async function fetchResponse() {
  const prompt = document.querySelector('#prompt').value;
  const dialogContainer = document.querySelector('#dialogContainer');

  if (!prompt.trim()) {
    displayError(dialogContainer, 'Please enter a prompt.');
    return;
  }

  try {
    const response = await getOpenAIResponse(prompt);
    const responseElement = document.createElement('p');
    simulateTyping(response, responseElement);
    dialogContainer.appendChild(responseElement);
    clearInput('#prompt');
  } catch (error) {
    displayError(dialogContainer, 'Error fetching data from OpenAI API.');
  }
}

// Function to fetch a generated image from OpenAI
async function fetchImage() {
  const prompt = document.querySelector('#imagePrompt').value;
  const imageContainer = document.querySelector('#imageContainer');
  const spinner = document.querySelector('#spinner');

  if (!prompt.trim()) {
    displayError(imageContainer, 'Please enter an image prompt.');
    return;
  }

  clearContainer(imageContainer);
  spinner.style.display = 'block';

  try {
    const imageUrl = await generateImage(prompt);
    displayImage(imageUrl, imageContainer);
    clearInput('#imagePrompt');
  } catch (error) {
    displayError(imageContainer, 'Error generating image from OpenAI API.');
  } finally {
    spinner.style.display = 'none';
  }
}

// Function to handle microphone for speech-to-text
async function handleMicrophone() {
  const micButton = document.getElementById('micButton');
  let mediaRecorder;
  let stream;

  if (!navigator.mediaDevices) {
    alert('Microphone access is not supported in this browser.');
    return;
  }

  try {
    stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorder = new MediaRecorder(stream);
    const audioChunks = [];

    mediaRecorder.ondataavailable = (event) => audioChunks.push(event.data);
    mediaRecorder.onstop = async () => {
      const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
      const transcription = await transcribeAudio(audioBlob);
      if (transcription) {
        document.querySelector('#prompt').value = transcription;
      }
      resetMicButton(micButton);
    };

    mediaRecorder.start();
    micButton.style.backgroundColor = 'red'; // Indicate recording is on

    setTimeout(() => stopRecording(mediaRecorder, stream, micButton), 5000); // Auto-stop after 5 seconds
  } catch (error) {
    console.error('Error accessing microphone:', error);
    resetMicButton(micButton);
  }
}

// Helper functions

function stopRecording(mediaRecorder, stream, micButton) {
  mediaRecorder.stop();
  stream.getTracks().forEach((track) => track.stop());
  resetMicButton(micButton);
}

function resetMicButton(micButton) {
  micButton.style.backgroundColor = 'white';
}

function simulateTyping(text, container, delay = 50) {
  let i = 0;
  const interval = setInterval(() => {
    if (i < text.length) {
      container.textContent += text.charAt(i);
      i++;
    } else {
      clearInterval(interval);
    }
  }, delay);
}

function displayImage(url, container) {
  const imgElement = document.createElement('img');
  imgElement.src = url;
  imgElement.alt = 'Generated Image';
  imgElement.className = 'generated-image';
  container.appendChild(imgElement);
}

function displayError(container, message) {
  const errorElement = document.createElement('p');
  errorElement.textContent = message;
  errorElement.style.color = 'red';
  container.appendChild(errorElement);
}

function clearContainer(container) {
  container.innerHTML = '';
}

function clearInput(selector) {
  document.querySelector(selector).value = '';
}

// Sign-up Function (You will need to add your authentication logic here)
async function handleSignUp() {
  const email = document.querySelector('#email').value;
  const password = document.querySelector('#password').value;
  const authContainer = document.querySelector('#authContainer');

  try {
    // reCAPTCHA validation
    grecaptcha.enterprise.ready(async () => {
      const token = await grecaptcha.enterprise.execute('6LdZEXopAAAAAGHKMBQYRna6LrnhWBn4m179iS7o', { action: 'submit' });

      // Continue with the sign-up only after getting the reCAPTCHA token
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);

      // Send email verification
      await sendEmailVerification(userCredential.user);
      authContainer.textContent = `Signed up successfully! Please check your email for verification.`;

      // Close the modal after successful sign-up
      closeModal('signupModal');
    });
  } catch (error) {
    authContainer.textContent = `Error signing up: ${error.message}`;
  }
}
